import React from 'react';
import bgEarth from '../assets/bg-earth.png';
import bgStars from '../assets/bg-stars.png';
import Button from '../components/ui/button';
import translations from '../utils/translations';
import * as style from './404.module.scss';

class NotFoundPage extends React.Component {
  render() {
    return (
      <div style={this.props.transition && this.props.transition.style}>
        <div id="content">
          <div
            className={style.contentBackground}
            style={{ backgroundImage: 'url(' + bgStars + ')' }}
          >
            <div className={`${style.contentInner} text-block`}>
              <img src={bgEarth} alt="Earth" />
              <div className={style.contentText}>
                <div>
                  <h1>{translations.t('404 Error: Page not found')}</h1>
                </div>
                <Button color="white" to="/" text={translations.t('Head Back Home')} />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default NotFoundPage;
